.category-list{
    display: flex;
    height: auto !important;
    .category-item{
        margin: 0 0.5em;
        figure{
            padding-top: 100%;
        }
        span.tag{
            position: absolute;
            bottom: 0;
            left: 0;
        }


        &[data-category="theatre"]{
            span.tag{
                background-color: $theatre;
            }
        }
        &[data-category="film"]{
            span.tag{
                background-color: $film;
            }
        }
        &[data-category="community"]{
            span.tag{
                background-color: $community;
            }
        }
        &[data-category="classes"]{
            span.tag{
                background-color: $classes;
            }
        }
        &[data-category="dance"]{
            span.tag{
                background-color: $dance;
            }
        }
        &[data-category="music"]{
            span.tag{
                background-color: $music;
            }
        }
        &[data-category="family"]{
            span.tag{
                background-color: $family;
            }
        }
        &[data-category="friends"]{
            span.tag{
                background-color: $friends;
            }
        }
        &[data-category="comedy"]{
            span.tag{
                background-color: $comedy;
            }
        }
    }
}

.nav-category-swiper{
    .category-list{
        .category-item{
            margin: 0;
            a{
                padding: 0 !important;
            }
            span.tag{
                font-size: 0.8rem;
                @include media($bp-l){
                    font-size: 0.6rem;
                }
            }
        }
    }
}