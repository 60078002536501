section.spec-list{
    .container{
        padding: $pad/2;
        background-color: rgba($blue, 0.1);
        border-top: 5px solid $blue;
    }
    
    h3{
        margin: 0;
        color: $blue;
    }
    ul.spec-list{
        float: none;
        margin: 1em 0.5em;
        padding-left: 1em;
        li{
            display: list-item;
            float: none;
            list-style: disc;
        }
    }
    em{
        font-size: $font-s;
    }
}