section.split-media{
    .split-media{
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
		text-align: center;

        &-container.alt{
            .split-media__image{
                right: 0;
                left: auto;
            }
            .split-media__text{
                float: left;
            }
        }

		&__image{
			position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            text-align: center;
            figure{
                height: 100%;
            }
            img{
                display:inline-block;

                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
            @include media($bp-s){
                position: relative;
                width: 100%;
                height: 50%;
            }
		}
        &__text{
            float: right;
            width: 50%;
            padding:$pad;
            h2{
                margin-top: 0;
                color: $dblue;
            }
            .cta{
                width: 100%;
            }
            span.opening-info{
                color: $lblue;
                @extend .fa-clock;
                &::before{
                    @extend .fas;
                    margin-right: 0.5em;
                }
            }
            @include media($bp-s){
                width: 100%;
                padding: $pad/2 0;
            }
        }
	}
    &#expanding-text{
        .split-media{
            &__text{
                .expanding-text{
                    position: relative;
                    max-height: 100px;
                    overflow: hidden;
                    transition: max-height 1s ease;
                    
                    &.-expanded {
                      max-height: 100vh;
                    }
                  }
                  
                  .expanding-text:not(.-expanded)::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
                    z-index: 1;
                  }
            }
        }
    }
}

#map{
    height: 50vh;
}