section.social{
    main{
        width: calc(100% + 120px);
        left: -$pad;
    }
    ul.social-links{
        margin: 1.5em 0;
        width: 100%;
        li{
            display: inline-block;
            float: none;
            margin: 0 2em;
            @include media($bp-s){
            margin: 1rem;
            }
            a{
                &::before{
                    @extend .fab;
                    font-size: 1.8rem;
                    color: $blue;
                }
                &:hover{
                    &::before{
                        color: $dblue;
                    }
                }
            }
            &.twitter a{
                @extend .fa-x-twitter;
            }
            &.instagram a{
                @extend .fa-instagram;
            }
            &.facebook a{
                @extend .fa-facebook;
            }
            &.linkedin a{
                @extend .fa-linkedin-in;
            }
            &.tripadvisor a{
                //@extend .fa-tripadvisor;
            }
        }
    }
    ul.social-posts{
        display: flex;
        float: none;
        li{
            width: 25%;
            overflow: hidden;
            img{
                @include transition-all(.3s);
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
    }
}

//share this
.share-article{
    margin-top: $pad;
    padding: $gutter/2 0;
    border: 1px solid #aeaeae;
    border-left: 0;
    border-right: 0;
    span.title{
        display: inline-block;
        @include vertical-align;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .a2a_kit{
        display: inline-block;
        float: right;
        width: auto;
        .a2a{
            &_dd{
                display: none;
            }
        }
        [class*='_button_']{
            .a2a_svg{
                background-color: transparent !important;
                svg path{
                    fill: $blue;
                }
            }
        }
    }
}