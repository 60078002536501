div.accordion-list{
    &__item{
        &:first-of-type{
            border-top: 1px solid #EAEBED;
        }
        border-bottom: 1px solid #EAEBED;
        .accordion-item{
            &__header{
                color: $dblue;

                h3{
                    float: left;
                    font-size: 1rem;
                }
                span.venue-capacity{
                    @include vertical-align;
                    left: 50%;
                    @extend .fa-users;
                    color: $lblue;
                    &::before{
                        @extend .fas;
                        margin-right: 0.5em;
                    }
                }
                span.accordion-toggle{
                    @include vertical-align;
                    right: 0;
                    @extend .fa-chevron-down;
                    @extend .fas;
                }

                &:hover{
                    cursor: pointer;
                }
            }
            &__content{
                display: none;
                padding: $gutter/2;
                padding-bottom: $gutter;
                p{
                    margin: 0;
                }
            }
        }
        &.open{
            .accordion-item__header{
                span.accordion-toggle{
                    @extend .fa-chevron-up;
                }
            }
        }
    }
}