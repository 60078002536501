//filter
section.events-filter{
    h2{
        font-size: 1.5rem;
        color: $dblue;
        span{
            color:$blue;
        }
    }
}
.filter-container{
    .select-container{
        display: inline-block;
        width: auto;
    }
    .view-button-container{
        float: right;
        display: inline-block;
        width: auto;
        height: 48px;
        button{
            width: 48px;
            height: 100%;
            font-size: 0;
            border: 1px solid $blue;
            border-radius: 0;
            &:first-of-type{
                margin-right: -0.5em;
            }
            &::before{
                @extend .fal;
                @include middle;
                color: $blue;
                font-size: 1rem;
            }
            &.list-view{
                @extend .fa-list;
            }
            &.calendar-view{
                @extend .fa-calendar-alt;
            }
            &.active{
                background-color: $blue;
                &::before{color: $white;}
            }
        }
    }
    select.select-filter{
        padding: 0.8em 1em;
        padding-left: 2em;
        color: $dblue;
        border-color: $blue;
        border-radius: 0;
    }
}
.select-container.active{
    .select-btn{
        border-bottom: 1px solid $white;
        z-index: 10;
        span{
            @extend .fa-chevron-up;
        }
    }
}
.select-btn{
    float: none;
    bottom: -1px;
    width: fit-content;
    padding: 10px 40px;
    padding-left: 40px;
    border: 1px solid $dblue;
    &:hover{
        cursor: pointer;
    }
    &::before{
        content: '#';
        @include vertical-align;
        left: 1em;
        color: $blue;
        font-weight: bold;
        z-index: 10;
    }
    span{
        @extend .fa-chevron-down;
        &::before{
            @extend .fas;
            @include vertical-align;
            right: -2em;
            color: $blue;
            font-size: $font-s;
        }
    }
}
.select-options{
    padding: 5px;
    max-width: 450px;
    background-color: rgba($dblue, 0.1);
    border: 1px solid $dblue;
    ul.options{
        li.option{
            margin: 5px;
            padding: 0 5px;
            font-size: $font-s;
            color: $dblue;
            text-transform: uppercase;
            border: 1px solid $dblue;
            &:hover{
                cursor: pointer;
            }
            &.active{
                color: $white;
                background-color: $dblue;
            }
        }
    }
}