section.search-header{
    h1{
        font-size: 2.3rem;
        font-weight: bold;
        color: $dblue;
        .search-query{
            color: $blue;
        }
    }
}

section.search-results{
    padding-top: 0;
    .results{
        margin-bottom: 2em;
        h2{
            font-size: $font-l;
            color: $dblue;
            .results-no{
                color: $blue;
            }
        }

        &#page-results{
            .card-list{
                .card-item{
                    &__desc{
                        padding: 0;
                        .card-desc{
                            &__root{
                                color: $lgrey;
                                font-size: $font-s;
                                font-style: italic;
                            }
                            &__title{
                                margin: 0;
                                color: $dblue;
                            }
                            &__link{
                                position: absolute;
                                right: $gutter/2;
                                bottom: $gutter/2;
                                font-size: $font-s !important; 
                            }
                        }
                    }
                    a{
                        text-decoration: none;

                        
                    }
                }
            }
        }
    }
}

.select-container#search-select{
    .select-btn{
        float: none;
        bottom: -1px;
        width: fit-content;
        padding: 10px 40px;
        border: 1px solid $dblue;
        &::before{
            content: '#';
            @include vertical-align;
            left: 1em;
            color: $blue;
            font-weight: bold;
            z-index: 10;
        }
    }
    .select-options{
        //display: none;
        padding: 0;
        background-color: rgba($dblue, 0.1);
        border: 1px solid $dblue;
        ul.options{
            li.option{
                width: 100%;
                margin: 0;
                padding: 15px 10px;
                font-size: $font-s;
                color: $dblue;
                text-transform: uppercase;
                border: none;
                border-bottom: 1px solid $dblue;
                &:last-of-type{
                    border:none;
                }
                &:hover{
                    cursor: pointer;
                }
                &.active{
                    color: $white;
                    background-color: $dblue;
                }
            }
        }
    }

    &.active{
        .select-options{
            display: block;
        }
    }
}