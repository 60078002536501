section.sub-banner{
    padding: 0;
    figure{
        display: inline-block;
        width: 100%;
        height: 50vh;
        @include media($bp-s){
            display: block;
            width: 100%;
        }
    }
    @include media($bp-s){
        //margin-bottom: $pad;
    }
}