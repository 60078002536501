section.contact{
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    color: $dblue;
    background-color: $bg;

    @include media($bp-s){
        margin-top: -$pad;
    }

    ul.contact-list{
        margin: 0 auto;
        float: none;
        li{
            float: none;
            display: inline-block;
            width: 49%;
            padding: 1.5em;
            strong{
                text-transform: uppercase;
                letter-spacing: 2px;
            }
            p{
                margin: 0;
            }
            &:first-child{
                &::before{
                    content:'';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: calc(100% - 3em);
                    transform: translateY(-50%);
                    background-color: $dblue;
                }
            }

            @include media($bp-s){
                display: block;
                width: 100%;

                &:first-child{
                    border-bottom: 1px solid $dblue;
                    &::before{display: none;}
                }
            }
        }
    }
}