$reg:300;
$bold:700;

$font-s:0.85rem;
$font-l:1.5rem;

@font-face {
	font-family: corisande;
	src: url('../fonts/corisanderegular-webfont.woff');
	font-weight: 300;
}

@font-face {
	font-family: corisande;
	src: url('../fonts/corisandebold-webfont.woff');
	font-weight: bold;
}

@font-face {
	font-family: corisande;
	src: url('../fonts/corisandelight-webfont.woff');
	font-weight: 200;
}

$bodyFont: 'corisande', sans-serif;

body,
%bodyFont{
	font-family: $bodyFont;
	font-weight: $reg;
	font-style: normal;
	font-size:16px;
	line-height:1.5;
	letter-spacing:0.03em;
	color:$black;
}

%navType{
	font-weight:$reg;
	text-transform: uppercase;
	letter-spacing:0.12em;
	font-size:$font-s;
}

%boldType{
	@extend %navType;
	font-weight:$bold;
}

h1,
h2,
h3{
	font-weight:$reg;
	strong,
	a{
		color:$blue;
		font-weight:$reg;
		display:inline-block;
	}
}

h1,
%h1{
	font-size:3rem;
	line-height:1.2;
}

h1{
	span.category{
		@extend %boldType;
		font-size:$font-s;
		display:block;
		margin-bottom:1rem;
		color:$dblue;
	}
}

h2, h3{
	//text-transform: uppercase;
	font-weight: bold;
}

h2,
%h2{
	font-size:2.25rem;
	line-height:1.3;
}

h3,
%h3{
	font-size:$font-l;
	line-height:1.3;
}

h4,
%h4{
	font-size:1.2rem;
	line-height:1.3;
}

h5,
%h5{
	font-size:1.2rem;
	line-height:1.3;
	font-weight:300;
}

a{
	color:$dblue;
	text-decoration:underline;
	&:hover{
		text-decoration: none;
	}
}

a.block,
%noLink{
	color:inherit;
	text-decoration:none;
}

.cta,
%cta{
	display:inline-block;
	@extend %boldType;
	background:$blue;
	color:$white;
	padding:1.3em 2.3em;
	text-decoration:none;
	text-align: center;
	margin:1.5rem 0 0;
	letter-spacing: 2px;
	border: 2px solid transparent;
	@include transition-all(.3s);
	&:hover,
	&:focus{
		background-color: $dblue;
	}
	// &.more{
	// 	min-width:calc(0.34 * #{$mw} - #{$gutter});
	// }
	&.red{
		background-color: $red;
		&:hover,
		&:focus{
			background-color: darken($red, 10);
		}
	}
	&.alt{
		background-color: transparent;
		color: $blue;
		border-color: $blue;
		&:hover{
			background-color: $blue;
			color: $white;
		}
	}
	@include media($bp-s){
		width: 100%
	}
}

a.min{
	@extend %noLink;
	@extend %boldType;
	&::after{
		content:"";
		display:block;
		position:absolute;
		top:50%;
		margin-top:1em;
		left:50%;
		@include translateX(-50%);
		width:0;
		height:1px;
		background:$blue;
		@include transitions(width $dur $easing);
	}
	&:hover,
	&:focus{
		&::after{
			width:calc(100%);
		}
	}
}

button{
	background-color: transparent;
	border: none;
}

header.local,
footer.local{
	text-align:center;
	float:none;
	overflow:hidden;
	clear:both;
	max-width:960px;
	margin-left:auto;
	margin-right:auto;
	@include edgeMargins;
}

.list-social{
	li.list-social-item{
		margin-right:1rem;
	}
	a{
		color:inherit;
		text-decoration:none;
		font-size:0;
		&::before{
			@extend .fab;
			font-size:$font-l;
			font-weight:normal;
		}
	}
	.twitter{
		@extend .fa-twitter;
	}
	.facebook{
		@extend .fa-facebook-f;
	}
	.linkedin{
		@extend .fa-linkedin-in;
	}
	.youtube{
		@extend .fa-youtube;
	}
	.instagram{
		@extend .fa-instagram;
	}
}

