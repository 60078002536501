section.trustees-board{
    .inner{
        // max-width: 720px;
        // margin: 0;
    }
    p{
        max-width: 960px;
    }
}

ul.management-list{
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    li{
        float: none;
        padding: 1em 0;
        margin-right: 2em;
        width: calc(25% - 2em);
        &>*{
            display: block;
        }
        a{
            color: $black;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        @include media($bp-m){
            width: 100%;
            border-bottom: 1px solid #ccc;
            &:first-child{
                border-top: 1px solid #ccc;
            }
        }
    }
    @include media($bp-s){
        width: 100%;
    }
}

ul.trustees-list{
    padding-left: 1em;
    li{
        display: list-item;
        float: none;
        list-style: disc;
        margin: 0.5em 0;
        font-weight: bold;
    }
}