section.intro{
    padding-bottom: 0;
    &#event-intro{
        h1{
            display: block;
            color: $black;
        }
        p{
            display: block;
            margin: 0.5em 0;
            max-width: 75%;
            width: 100%;
        }
        .event-info{
            .location{
                margin-left: 0.5em;
                @extend .fa-map-marker-alt;
                &::before{
                    @extend .fas;
                    margin:0 0.3em;
                }
            }
            em{
                display: block;
                margin-bottom: 1.5em;
                color: #ccc;
            }
        }
        .ticket-pricing{
            margin-top: 1.5em;
            p{
                margin: 0;
            }
        }
    }
    &:not(#event-intro) .container{
        @include media($bp-s, min){
            max-width: 960px;
        }
    }
    &#event-intro{
        .event-gallery{
            position: sticky;
            top: 1em;
            left: 0;
            width: calc(50% - 2em);
            margin-right: 2em;
            @include media($bp-s){
                position: relative;
                top: 0;
                width: 100%;
            }
        }
        .event-content{
            width: 50%;
            @include media($bp-s){
                width: 100%;
            }
        }
    }
    h1{
        display: inline-block;
        color: $dblue;
        margin: 0;
    }
    strong{
        //display: block;
    }
    span.venue-capacity{
        display: block;
        color: $lblue;
        @extend .fa-users;
        &::before{
            @extend .fas;
            margin-right: 0.5em;
        }
    }

    span.category-tag{
        display: inline;
        padding: 0.1em 0.2em;
        width: fit-content;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        background-color: $black;
        @include media($bp-s){
            font-size: 0.8rem;
        }
        a{
            color: $white;
            text-decoration: none;
        }

        &[data-category="theatre"]{
            background-color: $theatre;
        }
        &[data-category="film"]{
            background-color: $film;
        }
        &[data-category="community"]{
            background-color: $community;
        }
        &[data-category="classes"]{
            background-color: $classes;
        }
        &[data-category="dance"]{
            background-color: $dance;
        }
        &[data-category="music"]{
            background-color: $music;
        }
        &[data-category="family"]{
            background-color: $family;
        }
        &[data-category="friends"]{
            background-color: $friends;
        }
        &[data-category="comedy"]{
            background-color: $comedy;
        }
    }

    &#support-intro{
        h1{
            color: $red;
        }
    }
}