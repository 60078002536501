section.newsletter-intro{
    padding-bottom: 0;
    .container{
        float: none;
        margin: 0 auto;
        max-width: 720px;

        h1{
            font-weight: bold;
            color: $dblue;
        }
    }
}
section.newsletter-signup-container{
    padding-top: 0;
    .container{
        float: none;
        margin: 0 auto;
        padding: $gutter+$gutter/2;
        max-width: 720px;
        background-color: rgba($blue, 0.1);
        @include media($bp-s){
            padding: $gutter;
        }
        
        h3{
            margin: 0;
            margin-bottom: 1em;
            font-size: 1.1rem;
            color: $blue;
        }
        a{
            color: $black;
        }
        p.copyright-text{
            display: inline-block;
            margin: 0;
            font-size: 0.75rem;
        }
        form{
            margin: 0;

            .field-wrapper{
                margin-bottom: 1em;
                &.split{
                    @include media($bp-s, min){
                        margin: 0 0.5em;
                        width: calc(50% - 1em);
                        &:nth-child(even){
                            left: 0.5em;
                        }
                        &:nth-child(odd){
                            right: 0.5em;
                        }
                    }
                }
                &.validationFail{
                    input{
                        border-color: $red;
                    }
                    .form-validation{
                        display: block;
                    }
                }
                label{
                    display: block;
                    width: 100%;
                    color: $blue;
                }
                input{
                    padding: $gutter/2;
                    width: 100%;
                    border: 1px solid rgba($lblue, 0.5);
                }
                .form-validation, .field-validation-error{
                    margin-top: 0.5em;
                    font-size: $font-s;
                    color: $red;
                    &::before{
                        content: '▲';
                        margin-right: 0.3em;
                        font-size: 0.75em;
                    }
                }
                .input-validation-error{
                    border-color: $red;
                }
            }

            p.copyright-text{
                margin: 1.5em 0;
            }
            button{
                margin: 0;
                width: 100%;
            }
        }
    }
}