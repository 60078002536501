section.services{
    ul.list-cards{
        display: flex;
        gap: $gutter;
        float: none;
        @include media($bp-s){
            display: block;
        }
        li.list-cards-item{
            margin: 0 1em;
            width: 33.333%;
            text-align: center;
            text-transform: uppercase;
            color: $white;
            background-color: $blue;
            overflow: hidden;

            figure{
                padding-top: 125%;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    max-height: 100%;
                    @include transition-all(.3s);
                }

                &::before{
                    content:'';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
    
                    background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 25%);
                }
    
            }
            h3{
                position: absolute;
                bottom: 0;
                width: 100%;
            }

            &:hover{
                img{
                    transform: scale(1.1);
                }
            }

            @include media($bp-s){
                margin: 1em 0;
                width: 100%;
            }
        }
    }
}