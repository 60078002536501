.card-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-top: -10px;
    margin-left: -10px;
    padding: 0!important;
    @include media($bp-xs){
        display: block;
    }
    .card-item{
        padding: 1rem;
        width: calc(25% - 10px);
        margin-left: 10px;
        margin-top: 10px;
        border: 1px solid #aeaeae;

        @include media($bp-m){
            width: calc(33.33% - 10px);
        }

        @include media($bp-s){
            width: calc(50% - 10px);
        }

        &.featured{
            border-top: 5px solid $blue;
        }

        @include media($bp-xs){
            width: 100%;

            &:not(.featured){
                .card-item__image{
                    display: inline-block;
                    width: 30%;
                    figure{
                        display: inline-block;
                        width: 100%;
                    }
                }
                .card-item__desc{
                    padding-top: 0;
                    padding-left: 1em;
                    width: 70%;

                    .card-desc__member{
                        margin-top: -1em;
                    }
                }
            }
        }

        &:hover{
            img{
                transform: scale(1.1);
            }
        }
        &__image{
            display: block;
            figure{
                padding-top: 100%;
                overflow: hidden;
                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                    max-height: 100%;
                    @include transition-all(.3s);
                }
            }
        }
        &__desc{
            padding-top: 1rem;

            .card-desc{
                &__member{
                    display: block;
                    width: 30px;
                    height: 35px;
                    float: right;
                    right: 0;
                    margin-top: -30px;
                    padding: 1.5em 0 1em 0;
                    background-color: $red;
                    @extend .fa-users;
                    &::before{
                        @extend .fas;
                        position: absolute;
                        bottom: 0.5em;
                        left: 50%;
                        transform: translateX(-50%);
                        color: $white;
                        font-size: 0.8rem;
                    }
                }
                &__tag{
                    display: inline-block;
                    padding: 0.1em 0.2em;
                    width: auto;
                    color: $white;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: bold;
                    background-color: $black;
                    @include media($bp-s){
                        font-size: 0.8rem;
                    }
                }
                &__title{
                    margin: 0.5em 0;
                    color: $black;

                    @include media($bp-s){
                        margin: 0.3em 0;
                        font-size: 1.2rem;
                    }
                }
                &__date{
                    display: block;
                    font-weight: bold;
                }
                &__info{
                    display: block;
                    margin: 0.3em 0;
                    font-size: $font-s;
                    font-style: italic;
                    color: #aeaeae;

                    @include media($bp-s){
                        font-size: 0.7rem;
                    }
                }
            }
        }

        //categories
        &[data-category="theatre"]{
           
            &.featured{
                border-top-color: $theatre;
            }
            
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $theatre;
                        }
                        &__date{
                            color: $theatre;
                        }
                    }
                }
            }
        }
        &[data-category="film"]{
            &.featured{
                border-top-color: $film;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $film;
                        }
                        &__date{
                            color: $film;
                        }
                    }
                }
            }
        }
        &[data-category="community"]{
            &.featured{
                border-top-color: $community;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $community;
                        }
                        &__date{
                            color: $community;
                        }
                    }
                }
            }
        }
        &[data-category="classes"]{
            &.featured{
                border-top-color: $classes;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $classes;
                        }
                        &__date{
                            color: $classes;
                        }
                    }
                }
            }
        }
        &[data-category="dance"]{
            &.featured{
                border-top-color: $dance;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $dance;
                        }
                        &__date{
                            color: $dance;
                        }
                    }
                }
            }
        }
        &[data-category="music"]{
            &.featured{
                border-top-color: $music;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $music;
                        }
                        &__date{
                            color: $music;
                        }
                    }
                }
            }
        }
        &[data-category="family"]{
            &.featured{
                border-top-color: $family;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $family;
                        }
                        &__date{
                            color: $family;
                        }
                    }
                }
            }
        }
        &[data-category="friends"]{
            &.featured{
                border-top-color: $friends;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $friends;
                        }
                        &__date{
                            color: $friends;
                        }
                    }
                }
            }
        }
        &[data-category="comedy"]{
            &.featured{
                border-top-color: $comedy;
            }
            .card-item{
                &__desc{
                    .card-desc{
                        &__tag{
                            background-color: $comedy;
                        }
                        &__date{
                            color: $comedy;
                        }
                    }
                }
            }
        }
    }

    &#latest-news{
        .card-item{
            &__desc{
                .card-desc{
                    &__title{
                        
                    }
                }
            }
        }
    }

    &.related-news-list{
        .card-item{
            @include media($bp-s,min){
                width: 49%;
                &__image{
                    padding-right: $gutter/2;
                    width: 50%;
                }
                &__desc{
                    width: 50%;
                }
            }
        }
    }
}