.select-container{
    .select-btn{

    }
    .select-options{
        display: none;
    }

    &.active{
        .select-options{
            display: block;
        }
    }
}