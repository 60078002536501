footer#footer-gbl{
    margin-top: $pad;
    color: $white;
    background-color: $dblue;
    a{
        color: $white;
        text-decoration: none;
    }
    h3{
        margin: 0;
        text-transform: uppercase;
        font-weight: 300;
    }
    p{
        margin: 0.8em 0;
    }
    address{
        margin: 0.8em 0;
        font-style: normal;
    }

    .footer-boxoffice, .footer-location{
        margin: 2em 0;
        width: 25%;
        a{
            text-decoration: underline;
        }
        @include media($bp-s){
            width: 100%;
        }
    }

    ul.footer-social-links{
        li{
            a{
                display: inline-block;
                float: none;
                margin: 1em 1.5em 1em 0em;
                &::before{
                    @extend .fab;
                    font-size: 1.8rem;
                }
                &:hover{
                    &::before{
                        opacity: 0.8;
                    }
                }
            }
            &.twitter a{
                @extend .fa-x-twitter;
            }
            &.instagram a{
                @extend .fa-instagram;
            }
            &.facebook a{
                @extend .fa-facebook;
            }
            &.linkedin a{
                @extend .fa-linkedin-in;
            }
        }
    }

    .footer-links{
        margin: 2em 0;
        width: 25%;
        ul.quick-links, ul.about-links{
            li{
                display: block;
                float: none;
            }
        }

        a{
            display: block;
            margin-bottom: 1em;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 2px;
        }

        ul.about-links{
            padding-left: 1em;
            border-left: 5px solid $lblue;
            li{
                a{
                    margin: 0.8em 0;
                    text-transform: none;
                    font-weight: 300;
                    letter-spacing: normal;
                }
                &:first-child a{
                    margin-top: 0;
                }
                &:last-child a{
                    margin-bottom: 0;
                }
            }
        }
        @include media($bp-s){
            width: 50%;
        }
    }

    .footer-sub{
        font-size: $font-s;
        color: rgba($white, 0.7);
        div{
            display: inline-block;
            width: auto;
        }
        a{
            color: rgba($white, 0.8);
        }
        ul.footer-sub-links{
            li{
                padding-right:1em;
                margin-right: 1em;
                &::before{
                    content: '|';
                    position: absolute;
                    right: 0;
                    color: $lblue;
                }
            }
        }
        .footer-notice, .charity{
            margin-right: 1.5em;
        }
    }

    .footer{
        &-boxoffice, &-location, &-links{
            padding-right: $gutter;
        }
    }
}