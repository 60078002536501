.gallery{
    height: max-content;
    h2{
        display: inline-block;
        color: $dblue;
    }
    .gallery-slider{
        .gallery-slide{
            figure{
                height: 100%;
                img{
                    width: 100%;
                }
                figcaption{
                    margin: 0.5em 0;
                    padding-left: 0.5em;
                    border-left: 5px solid $blue;
                }
            }
        }
    }

    &#event-gallery{
        height: max-content;
        .gallery-swiper{
            overflow: hidden;
        }
        .gallery-slider{
            .gallery-slide{
                figure{
                    //height: 100%;
                    padding-top: 100%;
                    img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                        max-height: 100%;
                        @include transition-all(.3s);
                    }
                }
            }
        }
        .swiper-pagination{
            padding-right: 0.5em;
            text-align: right;
            .swiper-pagination-bullet{
                border: 1px solid $white;
                background-color: transparent;
                opacity: 1;
                &-active{
                    background-color: $white;
                }
            }
        }
    }
}

.gallery-swiper-nav{
    display: inline-block;
    float: right;
    top: 1em;
    right: $pad;
    width: auto;
    .swiper-button{
        width: 45px;
        height: 45px;
        border: 3px solid $blue;
        border-radius: 50%;
        &::after{
            @include middle;
            font-size: 1.2rem;
            color: $blue;
            font-weight:bold;
        }
        &-prev{
            right: 0;
            left: auto;
        }
        &-next{
            left: 10px;
            right: auto;
        }
    }
}