body.vidbox-open{
	overflow:hidden;
}

#lightBox{
	@extend %dpCover;
	position:fixed;
	z-index:9999;
	background:rgba(#000,0.8);
	*{
		max-width:100%;
	}
	.frame{
		@include middle;
		width: auto;
		min-width:60%;
		min-width:70vw;
		max-width:100%;
		height:auto;
		padding:$pad/2;
	}
	.close{
		position:absolute;
		top:0;
		right:0;
		width:$pad/2;
		height:$pad/2;
		background:#aaa;
        text-shadow: none;
        opacity: 1;
		cursor:pointer;
		z-index:10;
		&::before{
			content: 'X';
			color:#000;
			position:absolute;
			top:50%;
			left:0;
			width:100%;
			text-align:center;
			line-height:0;
		}
	}
	@media only screen and (max-width: 800px) {
		.frame{
			padding:0;
			width:100%;
            max-width: 95vw;
		}
		.close{
			margin:0;
            width: 35px;
            height: 35px;
		}
	}
}

.video-popup-button{
	display:inline-block;
	float:none;
	width:$pad*2+10;
	height:$pad*2+10;
	margin:0 1em 1em;
	@extend .fa-play;
	font-size:0;
	&::before{
		content: 'watch trailer';
		text-transform: uppercase;
		font-size: 0.7rem;
		letter-spacing: 2px;
		font-weight: bold;
		//@extend .fa;
		@include middle;
		width:$pad*2+15;
		height:$pad*2+15;
		background:rgba($black,0.5);
		color:$white;
		@include border-radius(50%);
		line-height:$pad*2+15;
		text-align:center;
		text-indent:5px;
		// /font-size:50px;
		@include box-shadow(0 0 0 0 rgba($blue,0));
		@include transition(all,$dur);
		z-index:200;
	}
	&:hover{
		cursor:pointer;
		&::before{
			@include box-shadow(0 0 0 20px rgba($blue,0.6));
		}
	}
}

.inline-content-video{
	max-width: 1200px;
    margin: 4em auto;
    left: 50%;
    transform: translateX(-50%);
	overflow: hidden;
	.video-container{
		padding-bottom: 56.25%;
		margin:0 auto;
		&::before{
			content:'';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: 0.2;
			z-index: 10;
		}
	}
	.video-popup-button{
		@include middle; 
		z-index: 10;
	}
}