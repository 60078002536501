/*
Radiator Digital
http://www.radiatordigital.com
*/

//===================================================================//
// =====================  BASE VARIABLES   ======================== //
//=================================================================//

// DIMENSIONS & GRID
// --------------------------------
$desktop: 1180px;
$tablet: 960px - 1px;
$mobile: 780px - 1px;
$columns: (100% / 12);
$dmargin: 20px / $desktop * 100%;
$mmargin: 10px / $mobile * 100%;
$oneThird:(100/3)*1%;
$twoThird:(100/3)*2%;

$max: 1920px;
$mw: 1500px;
$th: 1000px;
$cw: 900px;
$sw: 700px;
$section: 40px;
$sectionlarge: 60px;
$unit: (1/12)*100%;


// BASE COLOR SET
// --------------------------------
$bg: #f0f0f0;
$charc: #333;
$slate: #424242;
$chalk: #fdfdfd;
$trans: rgba(#FFFFFF, 0);
$white: #fff;
$black: #000;
$w: #fff;
$b: #000;
$ddgrey: #444;
$dgrey: #666;
$mgrey: #8a8a8a;
$lmgrey: #ddd;
$lgrey: #cfcfcf;
$slgrey: #fafafa;
$rad: #C81E26;
$fb: #3b5998;
$tw: #288EC3;
$gplus: #dd4b39;
$yt: #bb0000;
$vine: #00bf8f;
$li: #007bb6;
$inst: #517fa4;
$pnt: #cb2027;
$tumblr: #35465c;
$radNja: #ff5225;
$radPnk: #db1561;
$rss: $radNja;
$linkedin: $li;
$snapchat: #fffc00;
$vk: #4c75a3;
$weibo: #df2029;
$weixin: #10D21C;


// FX
// --------------------------------
$shadow: 0px 0px 5px 0px #333;  // Global Box Shadow Style (if applicable)
$headShad: 0px 0px 10px $black;


// SELECTORS
//---------------------------------
$allHeadings: 'h1,h2,h3,h4,h5,h6';

// SOCIAL ICONS
// --------------------------------
$social: (
	'twitter': $tw, 
	'facebook': $fb, 
	'pinterest': $pnt, 
	'youtube': $yt, 
	'linkedin': $li,
	'linkedin-in': $li, 
	'instagram': $inst,
	'google-plus': $gplus,
	'vk': $vk,
	'weixin': $weixin,
	'weibo': $weibo,
	'envelope': $black,
);

