.media-block{
    color: $white;
    .small-title{color: $white;}
    &.wide{
        padding-left: 0;
        padding-right: 0;

        &.dark{
            .info{
                background-color: $black;
            }
            div.bg-swoosh{
                svg{
                    path{
                        stroke: $red;
                        fill: $red;
                        opacity: 0.1;
                    }
                }
            }
        }

        @include media($bp-s,min){
            .large-title{
                font-size: 5rem;
            }
        }

        &+.wide{
            margin-top:-$pad*2;
        }
    }
    &.member{
        .info{
            background-color: $lred;
            &::before{
                display: none;
            }
        }
    }
	.img{
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
		text-align: center;

		img{
			display:inline-block;

            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            -o-object-fit: cover;
            object-fit: cover;
		}

        @include media($bp-s){
            position: relative;
            width: 100%;
            height: 50%;
        }
	}
	.info{
        width: 50%;
		padding:$pad;
        background-color: $lblue;
        overflow: hidden;

        h2{
            color: $white;
        }
        &::before{
            content:'';
            display: block;
            float: left;
            width: 1px;
            margin-right: -1px;
            padding-top: 66.66%;
        }
	}
	&.align-alt{
		.img{
			float:right;
		}
	}
	@include media($bp-m){
		.info{
			padding:$gutter;
			margin-top:$gutter;
		}
	}
	@include media($bp-s){
		.info{
            width: 100%;
            margin: 0;
		}
	}
}