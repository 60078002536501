section.sponsors{
    h3{
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2px;
    }

    ul.sponsor-logos{
        display: flex;
        justify-content: center;
        width: 100%;
        li{
            margin: 0 0.8em;
            img{
                max-height: 100px;
            }
        }

        @include media($bp-s){
            li{
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
