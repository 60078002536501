%dfp{
	display: block;
	position: relative;
	float: left;
	height: auto;
}

%dpa{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

%dpCover{
	@extend %dpa;
	width: 100%;
	height: 100%;
}

%noLink{
	text-decoration: none;
	color: inherit;
}

%bgc{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}