#onetrust-banner-sdk {
    &:focus, button:focus {
        outline: none !important;
    }
}
.privacy-notice-link,
.ot-always-active,
#onetrust-pc-sdk .ot-cat-item > button,
#onetrust-pc-sdk .ot-acc-cntr > button,
#onetrust-pc-sdk li > button {
    color: $blue !important;
}
.ot-switch-nob {
    border-color: $blue !important;
    background: rgba($blue, 0.15) !important;
    &::before {
        background: $black !important;
    }
}
.ot-pc-refuse-all-handler,
#onetrust-reject-all-handler,
#onetrust-accept-btn-handler,
#accept-recommended-btn-handler {
    display: inline-block;
    min-width: 150px;
    @apply px-5 #{!important};
    @apply py-3 #{!important};
    border: solid 1px transparent !important;
    border-radius: 0px !important;
    text-align: center !important;
    @apply text-xs #{!important};
    transition: background 0.2s ease-in-out !important;
    background: $blue !important;
    color: $white !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    &:focus,
    &:hover {
        background: darken($blue, 10%) !important;
        border-color: darken($blue, 10%) !important;
        opacity: 1 !important;
        outline: 0 !important;
    }
}
.save-preference-btn-handler.onetrust-close-btn-handler,
#onetrust-pc-btn-handler {
    display: inline-block;
    min-width: 150px;
    @apply px-5 #{!important};
    @apply py-3 #{!important};
    border: solid 1px transparent !important;
    border-radius: 0px !important;
    text-align: center !important;
    @apply text-xs #{!important};
    transition: background 0.2s ease-in-out !important;
    background: $black !important;
    color: $white !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    &:focus,
    &:hover {
        background: $white !important;
        border-color: $black !important;
        color: $black !important;
        opacity: 1 !important;
        outline: 0 !important;
    }
}
#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
    background: $blue !important;
}
.ot-floating-button__front,
.ot-floating-button__back {
    > button {
        text-align: center;
        > svg {
            display: inline-block;
        }
    }
}
.onetrust-close-btn-handler.onetrust-close-btn-ui.banner-close-button.ot-close-icon {
    display: none !important;
}
#ot-sdk-btn-floating {
    display: none !important;
}
#onetrust-policy-text {
    @apply text-xs #{!important}
}