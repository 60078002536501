section#calendar-list-view{
    #calendar{
        margin-right: 2em;
        width: calc(50% - 2em);
        @include media($bp-s){
            margin: 0;
            width: 100%;
        }
    }
    .calender-event-dropdown{
        width: 50%;
        @include media($bp-s){
            width: 100%;
        }

        .event-list{
            @include media($bp-s, min){
                display: flex;
            }
            .event-item{
                padding: $gutter/2;
                width: 50%;
                margin-left: 10px;
                margin-top: 10px;
                border: 1px solid #aeaeae;
                @include media($bp-s){
                    margin-left: 0;
                    width: 100%;
                }

                span{
                    display: block;
                }

                .event-desc__category{
                    display: inline;
                    padding: 0.1em 0.2em;
                    width: fit-content;
                    color: $white;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: bold;
                    background-color: $black;
                    @include media($bp-s){
                        font-size: 0.8rem;
                    }
                }

                &[data-category="theatre"]{
                    .event-desc__category{
                        background-color: $theatre;
                    }
                }
                &[data-category="film"]{
                    .event-desc__category{
                        background-color: $film;
                    }
                }
                &[data-category="community"]{
                    .event-desc__category{
                        background-color: $community;
                    }
                }
                &[data-category="dance"]{
                    .event-desc__category{
                        background-color: $dance;
                    }
                }
                &[data-category="classes"]{
                    .event-desc__category{
                        background-color: $classes;
                    }
                }
                &[data-category="music"]{
                    .event-desc__category{
                        background-color: $music;
                    }
                }
                &[data-category="family"]{
                    .event-desc__category{
                        background-color: $family;
                    }
                }
                &[data-category="friends"]{
                    .event-desc__category{
                        background-color: $friends;
                    }
                }
                &[data-category="comedy"]{
                    .event-desc__category{
                        background-color: $comedy;
                    }
                }
            }
            .event-item__image{
                display: inline-block;
                width: 100%;
                padding-top: 100%;
                figure{
                    @include media($bp-s, min){
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        img{
                            height: 100%;
                        }
                    }
                }
                @include media($bp-s){
                    width: 30%;
                    padding-top: 0;
                }
            }
            .event-item__desc{
                padding-top: 1em;
                width: 100%;
                color: $black;
                @include media($bp-m){
                    padding-top: 0;
                    padding-left: 1em;
                    width: 70%;
                }
                .event-desc__title{
                    margin: 0.3em 0;
                    color: $dblue;
                }
                .event-desc__date{
                    color: $blue;
                    font-weight: bold;
                }
                .event-desc__info{
                    color: #aeaeae;
                    font-style: italic;
                }
                .event-desc__member{
                    margin-top: -1em;
                }
            }
            span.cta{
                display: inline-block;
                width: 100%;
            }
        }
        .no-event{
            padding: $pad;
            @include media($bp-m){
                display: none;
            }
            span{
                font-size: $font-l;
                font-weight: bold;
                color: $red;
            }
            p{
                margin: 0;
                margin-bottom: 2em;
                font-weight: bold;
                color: $mgrey;
            }
            &.hidden{
                display: none;
            }
        }
    }
}
.vanilla-calendar-header{
    &__content{
        position: absolute;
        display: block !important;
        padding: 0 !important;
        margin-bottom: 2em;
        button{
            color: $blue !important;
            font-size: $font-l;
        }
    }
}
.vanilla-calendar-arrow{
    position: absolute !important;
    right: 0;
    bottom: 0.5em;
    z-index: 10 !important;
    &_prev{
        right: 2em !important;
    }
}
.vanilla-calendar-week{
    margin-bottom: 1em !important;
    &__day{
        font-size: 1rem !important;
        color: $black !important;
    }
}
.vanilla-calendar-days{
    background-color: rgba($dblue, 0.1) !important;
}
.vanilla-calendar-day{
    padding: 5px;
}
.vanilla-calendar-day__btn{
    padding-top: 100% !important;
    background-color: transparent !important;
    &_today{
        border-radius: 50% !important;
        background-color: $blue !important;
        color: $white !important;
    }
    &_selected{
        color: $black !important;
    }
    &.events{
        background-color: $white !important;
    }
    &_today.events{
        background-color: $blue !important;
    }
    &_selected.events{
        background-color: rgba($blue, 0.2) !important;
    }
    span{
        @include middle;
        font-size: 1rem;
    }
    .event-wrapper{
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
    }
    .event{
        margin-right: 2px;
        width: 6px;
        height: 6px;
        border: 1px solid rgba($white, 0.3);
        &.category1{
            background-color: red;
        }
        &.category2{
            background-color: blue;
        }
        &.theatre{
            background-color: $theatre;
        }
        &.film{
            background-color: $film;
        }
        &.community{
            background-color: $community;
        }
        &.dance{
            background-color: $dance;
        }
        &.classes{
            background-color: $classes;
        }
        &.music{
            background-color: $music;
        }
        &.family{
            background-color: $family;
        }
        &.friends{
            background-color: $friends;
        }
        &.comedy{
            background-color: $comedy;
        }
    }
}
.vanilla-calendar-day__btn_today.vanilla-calendar-day__btn_selected{
    background-color: $blue !important;
    color: $white !important;
}