#menu-gbl{
    //display: none;
}

section#menu-gbl-menu{
    padding: $gutter $pad;
    ul.l0{
        width: 100%;
        li.l0{
            //padding-bottom: 15px;
            width: 100%;
            border-bottom: 1px solid $bg;
            &:hover{
                cursor: pointer;
            }
            a{
                display: block;
                padding: 20px 0;
                width: 90%;
                text-decoration: none;
                font-weight: bold;

                &.support-link{
                    padding-left: 20px;
                    @extend .fa-heart;
                    &::after{
                        content:'';
                        @extend .fas;
                        @include vertical-align;
                        left: 0;
                        color: $red;
                    }
                }
            }
            &.has-sub.l0{
                @extend .fa-chevron-down;
                &::before{
                    @extend .fas;
                    @include vertical-align;
                    top: 2em;
                    right: 0;
                    font-size: $font-s;
                    color: $blue;
                }
            }
            &.has-sub.active{
                @extend .fa-chevron-up;
                ul.l1{
                    display: block;
                }
            }
        }
    }
    ul.l1{
        display: none;

        padding-left: 1em;
        width: 100%;
        border-left: 8px solid $bg;
        li.l1{
            width: 100%;
            a{
                padding: 15px 0;
                font-weight: normal;
            }
        }
        &.has-list{
            padding: 0;
            border: 0;
        }
    }

    ul.menu-social-links{
        li{
            a{
                display: inline-block;
                float: none;
                margin: 1em 1.5em 1em 0em;
                @include media($bp-s){
                    //margin: 1rem;
                }
                &::before{
                    @extend .fab;
                    font-size: 1.8rem;
                    color: $blue;
                }
                &:hover{
                    &::before{
                        color: $dblue;
                    }
                }
            }
            &.twitter a{
                @extend .fa-x-twitter;
            }
            &.instagram a{
                @extend .fa-instagram;
            }
            &.facebook a{
                @extend .fa-facebook;
            }
            &.linkedin a{
                @extend .fa-linkedin-in;
            }
            &.tripadvisor a{
                //@extend .fa-tripadvisor;
            }
        }
    }

    ul.contact-list{
        margin: $gutter 0 $pad 0;
        color: $dblue;
        li{
            width: 100%;
            p{
                display: inline-block;
                width: auto;
                margin: 0.5em;
                margin-left: 0;
            }
        }
    }
    
    ul.menu-footer-links{
        li{
            margin-right: 1em;
            a{
                font-size: $font-s;
            }
        }
    }
    
    .support-link{
        padding-left: 20px;
        @extend .fa-heart;
        &::after{
            content:'';
            @extend .fas;
            @include vertical-align;
            left: 0;
            color: $red;
        }
    }
}
