section.testimonial{
    text-align: center;
    color: $blue;
    blockquote{
        max-width: 900px;
        padding: $gutter;
        margin: 0 auto;
        //margin-bottom: 1em;
        font-size:$font-l + 0.2rem;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-style: italic;

        &::before, &::after{
            content: '"';
            display: inline-block;
            margin: 0;
        }
        @include media($bp-s){
            font-size:$font-l;
        }
        @include media($bp-xs){
            font-size:1.2rem;
        }
    }
    author{
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 2px;
    }
}