.spec-container{
    //max-width: 25%;
}
ul.venue-specs{
    &__dimensions{
        margin-bottom: 0.5em;
        width: 100%;
        background-color: $lblue;
        color: $white;
        li{
            margin: 0.5em;
            padding: $gutter/2;
            width: calc(50% - 1em);
            h4{
                font-size: $font-s;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 2px;
                opacity: 0.7;
            }
            h4, p{
                margin: 0;
            }
            p{
                &::before{
                    margin-right: 0.5em;
                    color: $dblue;
                }
            }
            &.area{
                P{
                    @extend .fa-ruler-combined;
                    &::before{
                        @extend .fas;
                    }
                    &::after{
                        content: 'm\b2';
                    }
                }
            }
            &.height{
                P{
                    @extend .fa-ruler;
                    &::before{
                        @extend .fas;
                    }
                }
            }
        }
    }
    &__capacity{
        width: 100%;
        li{
            margin: 0.5em;
            padding: $gutter/2;
            width: calc(50% - 1em);
            background-color: #EAEBED;
            h4{
                font-size: $font-s;
                font-weight: 300;
                text-transform: uppercase;
                letter-spacing: 2px;
                opacity: 0.7;
                color: $lblue;
            }
            h4, p{
                margin: 0;
            }
            p{
                &::before{
                    margin-right: 0.5em;
                    color: $dblue;
                }
            }
        }
    }
}