header#header-gbl{
    padding: 0 $pad;
    border-bottom: 1px solid $bg;
    z-index: 9999;
    .inner{max-width: none;}
    .container{
        padding:$pad 0;
        @include media($bp-s){
            padding: $gutter+$gutter/2;
        }
    }
    .home-road{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        #header-logo{
            display: inline-block;
            padding: 0.5em;
            background-color: $blue;
            img{
                height: 120px;
                @include media($bp-s){
                    height: 100%;
                }
            }
        }
        // @include media($bp-m,min){
        //     position: fixed;
        //     top:0;
        // }
    }
    
    .header-title{
        display: inline-block;
        color: $blue;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5;

        // transform: translateX(180px) rotate(-90deg);
        @include vertical-align;
        left: 136px;

        @include media($bp-s){
            top: 40%;
            left: -235px;
            transform: translateX(180px) rotate(-90deg);
            font-size: 0.75rem;
            letter-spacing: normal;
        }
    }

    nav#header-nav{
        display: inline-block;
        float: none;
        width: auto;
        @include middle;
        ul.l0{
            li.l0{
                margin: 0 0.8em;
                a{
                    text-decoration: none;
                    font-weight: bold;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        @include media($bp-m){
            display: none;
            width: 100%;
            height: 50%;
        }
    }

    #header-actions{
        display: inline-block;
        width: auto;
        @include vertical-align;
        right: -0.5em;
        a, button{
            display: inline-block;
            margin: 0 1em;
            padding: 0;
            border: none;
            background-color: transparent;
            color: $dblue;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 2px;
            text-align: center;
            i{
                display: block;
                margin-bottom: 0.5em;
            }
            span{
                font-size: 0.6rem;
            }
            &.toggle-support, &.toggle-basket{
                bottom: -8px;
                vertical-align: middle;
                text-decoration: none;
            }
            &.toggle-support i{ color: $red; }
            @include media($bp-s){
                margin: 0 0.3em;
                span{
                    font-size: 0.5rem;
                }
            }
        }
    }

    @include media($bp-s){
        padding: 0 $pad/2;
    }
}

main#menu-gbl{
    position: absolute;
    top: 121px;
    right: 0;
    height: 100vh;
    background: rgba($dblue, 0.5);
    overflow: hidden;

    opacity: 0;
    z-index:-9999;

    @include transition-all(.3s);

    .menu-page{
        padding-bottom: $pad !important;
    }

    section#menu-gbl-menu{
        position: absolute;
        top: 0;
        right: -2000px;
        padding-bottom: 150px !important;
        background: white;
        max-width: 600px;
        height: 100vh;
        border-top: 2px solid $blue;
        overflow: scroll;

        @include transition-all(.5s);
    }

    section#menu-gbl-search{
        position: absolute;
        top: -2000px;
        right: 0;
        background: white;
        max-width: 600px;
        height: auto;
        border-top: 2px solid $blue;
    }

    &.open{
        opacity: 1;
        z-index: 9998;
        @include transition-all(.3s);
        section.menu-page{
            &.menu-open, &.search-open{
                top: 0 !important;
                right: 0 !important;
                @include transition-all(.3s);
            }
        }
    }
}

section#menu-gbl-search{
    header{
        margin-bottom: $gutter;
        h3{
            margin-top: 0;
            color: $dblue;
        }
        form{
            .input-wrapper{
                @extend .fa-search;
                &::before{
                    @extend .fas;
                    @include vertical-align;
                    left: 15px;
                    color: $blue;
                    z-index: 10;
                }
            }
            input{
                width: 100%;
                padding: 15px;
                padding-left: 40px;
                border: none;
                background-color: $bg;
            }
            button{
                width: 100%;
            }
        }
    }
    main{
        h4{
            margin-bottom: 0.5em;
            color: $dblue;
        }
        ul.quick-links{
            padding-left: $gutter;
            border-left: 8px solid $bg;
            li{
                width: 100%;
                a{
                    display: block;
                    padding: 5px 0;
                    text-decoration: none;
                }
            }
        }
    }
}