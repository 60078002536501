section.opening-hours{
    .opening-hours-container{
        max-width: 960px;
        padding: $gutter/2;
        border-top: 5px solid $blue;
        background-color: $bg;
        h2{
            margin-top: 0;
            color: $blue;
            font-size: $font-l;
        }
    }
}