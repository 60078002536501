// GBL VARS
$gutter:30px;
$pad:60px;

$bp-l: 1279px;
$bp-m:1023px;
$bp-s:720px;
$bp-xs:450px;

$dur:.3s;
$easing:cubic-bezier(0.65, 0, 0.35, 1);

$blue: #3C76C2;
$red: #B53850;
$bg: #EAEBED;

$dblue: #254680;
$lblue: #4E8FBE;
$lred: #DE96A1;

$theatre: #66254F;
$film: #3E87A4;
$community: #2155D5;
$dance: #DB586A;
$classes: #41913F;
$music: #9980FA;
$family: #006266;
$friends: #ED4C67;
$comedy: #EE5A24;


$white:#FFF;
$black:#000;

$mw:1200px;

// LIBRARY
@import '_lib/_normalize';
@import '_lib/_mixins';
@import '_lib/_placeholders';
@import '_lib/_grid';
@import '_lib/_variables';
// FONT AWESOME
@import 'font-awesome/scss/fontawesome'; // Main Family
@import 'font-awesome/scss/regular';  // Regular weight
@import 'font-awesome/scss/light'; // Light Weight
@import 'font-awesome/scss/solid'; // Solid Weight
@import 'font-awesome/scss/brands'; // Light Weight
@import 'font-awesome/scss/duotone'; // Light Weight
// GLOBAL
@import '_gbl/_gblLayout';
@import '_gbl/_gblType';
// MODULES
@import '_modules/header';
@import '_modules/footer';
@import '_modules/hero-banner';
@import '_modules/sub-banner';
@import '_modules/intro';
@import '_modules/accordion';
@import '_modules/calendar';
@import '_modules/card-list';
@import '_modules/category-list';
@import '_modules/contact';
@import '_modules/cookies';
@import '_modules/faqs';
@import '_modules/filter';
@import '_modules/gallery';
@import '_modules/management';
@import '_modules/media-block';
@import '_modules/menu';
@import '_modules/newsletter';
@import '_modules/opening-hours';
@import '_modules/search-results';
@import '_modules/services';
@import '_modules/social';
@import '_modules/spec-list';
@import '_modules/split-media';
@import '_modules/sponsors';
@import '_modules/tags-dropdown';
@import '_modules/testimonial';
@import '_modules/venue-specs';
@import '_modules/venue-map-card';
@import '_modules/video';
// VENDOR
@import 'vendor/swiper-bundle.min';
// @import 'vendor/vanilla-calendar.min';
// @import 'vendor/light.min';

.section-header{
    margin-bottom: $pad;
    text-align: center;
    color: $dblue;
    h2{
        margin: 0;
        font-size: 1.8rem;
    }
    @include media($bp-s){
        margin-bottom: $pad/2;
    }
}
.section-footer{
    margin-top: $pad;
    text-align: center;
}
.small-title{
    text-transform: uppercase;
    color: $lblue;
    font-weight: bold;
    letter-spacing: 2px;
}

.large-title{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: bold;
    letter-spacing: 2px;
}

.tag{
    padding: 0.1em 0.2em;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    background-color: $black;
}

ul.breadcrumbs{
    margin-bottom: 1em;
    width: 100%;
    li{
        margin-right: 1em;
        a{
            text-transform: uppercase;
            text-decoration: none;
            color: $lblue;
            font-weight: bold;
        }
        &::before{
            content:'/';
            position: absolute;
            right: -0.7em;
            color: $lblue;
        }
        &:last-child{
            &::before{display: none;}
        }
    }
}

div.notice{
    padding: $gutter/2;
    border-left: 5px solid $blue;
    background-color: rgba($blue, 0.1);
    color: $dblue;
    &.member{
        background-color: rgba($red, 0.1);
        border-color: $red;
        color: $red;
        a{
            color: $red;
        }
    }
    strong{
        //display: block;
    }
    p{
        display: inline-block !important;
        margin: 0 !important;
    }
}

div.bg-swoosh{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    z-index: 0;
    svg{
        width: 100%;
        height: 100%;
    }
}

//quote
div.inline-quote{
    float: none;
    margin: $pad 0;
    padding: 0 $pad/2;
    border-left: 5px solid $blue;
    color: $blue;
    blockquote{
        margin: 0;
        margin-bottom: 1em;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-style: italic;
        font-size: 1.1rem;

        &::before, &::after{
            content: '"';
            display: inline-block;
            margin: 0;
        }
    }
    author{
        text-transform: uppercase;
        font-weight: bold;
        font-size: $font-s;
        letter-spacing: 2px;
        span{
            display: block;
            text-transform: none;
            letter-spacing: normal;
        }
    }
}

section.rte{
    .container{
        float: none;
        margin: 0 auto;
        max-width: 800px;
    }
    h1{
        color: $dblue;
    }
    figure{
        height: 50vh;
        img{

        }
    }
}

.not-found{
    height: 100vh;
    &__container{
        @include middle;
        text-align: center;
        h1{
            color: $blue;
            font-weight: bold;
            font-size: 2.5rem;
        }
    }
    background-image: url('../images/bg-swoosh.svg');
    @include bgCover;
}