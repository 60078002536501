section.hero-banner{
    padding: 0;
    //height: 80vh;
    @include media($bp-s, min){
        height: 80vh;
    }

    figure{
        display: inline-block;
        width: 65%;
        height: 100%;
        @include media($bp-s){
            display: block;
            width: 100%;
            height: 50%;
        }
    }
    .banner-panel{
        float: right;
        display: inline-block;
        padding: $pad;
        width: 35%;
        height: 100%;
        color: $white;
        background-color: $dblue;

        .cta{
            width: 100%;
        }

        // &::before{
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-image: url('../images/bg-swoosh.svg');
        //     background-repeat: no-repeat;
        //     background-size: contain;
        //     background-position: bottom right;
        // }

        @include media($bp-s){
            display: block;
            width: 100%;
            height: 50%;
            padding: $pad/2;
        }
    }
    @include media($bp-s){
        margin-bottom: $pad;
    }
}