*{
	position:relative;
	z-index:1;
	@include backface-hidden;
	@include box-sizing;
	outline:0;
}

html,
body,
section,
header,
footer,
article,
aside,
main,
nav,
div{
	display:block;
	float:left;
	width:100%;
	height:auto;
	margin:0;
	padding:0;
}

html,
body{
	height:100%;
	width:100%;
	max-width:100%;
	overflow-x:hidden;
}

body{
	overflow-x:hidden;
	overflow-y:scroll;
	-webkit-overflow-scrolling: touch;
}

#window{
	width:100%;
	background:$white;
}

main#container{
	width:100vw;
}

.inner{
    max-width:$mw;
    float:none;
    margin:0 auto;
    @include selfClearing;
    @include edgeMargins;
}

header,
footer,
section{
	&.gbl{

		//Spacing fix 
		padding:$pad;

		//padding:2*$pad $pad;
		&+.gbl:not([class*=bg-]){
			//margin-top:-1.5*$pad;
		}
		@include media($bp-s){
			padding:$pad $gutter;
			&+.gbl,
			&+.gbl:not([class*=bg-]){
				//margin-top:-$pad;
			}
		}
	}
	&#header-gbl+.gbl{
		margin-top:0;
	}
}

section.gbl+section.gbl{
	padding-bottom: 0;
}

//Spacing fix
section.gbl.text-basic[id*=section-] + section.gbl.text-basic[id*=section-] {
	@include media($bp-s, min) {
		padding: 60px;
	}
}

.header-page+.text-basic.gbl{
	@include media($bp-s,min){
		padding-top:0 !important;
		padding-bottom: 100px;
	}
 }

 //menu open
 body.takeover-open{
	overflow: hidden;
 }

main{
	@include edgeMargins;
}

.header-page.gbl {

	padding-bottom: $pad / 2;
//	padding-top: $pad / 2;

	header.local {
		margin-bottom: 0 !important;
	}
}

header.local{
	margin-bottom:$gutter;
	&+*{
		clear:both;
	}
}

footer.local{
	clear:both;
	margin-top:$gutter;
}

section.gbl.header-page + section.gbl {
	margin-top: 0 !important;
}

img{
	max-width:100%;
	object-fit:cover;
	height: auto;

	&.cover{
        object-fit:cover;
        @include middle;
        min-width:100%;
        min-height:100%;
        background-color:$lgrey; //placeholder for image space if connection slow to load background image
    }
}

.img-bg > img,
img.img-bg{
	@extend %dpCover;
}

.video-bg > video {
	@extend %dpCover;
}

a.block{
	@extend %dfp;
	width:100%;
	color:inherit;
	text-decoration:none;
}

ul[class]{
	@extend %listless;
}

.bg-grey{
	background:$bg;
}
