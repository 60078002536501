section.venue-list{
    padding-top: 0;
}
.venue-card{
    margin-bottom: $pad;
    min-height: 50vh;
    &:last-of-type{
        margin: 0;
    }
    background-color: $bg;
    &__info{
        padding: $pad;
        width: 50%;
        h2{
            margin: 0;
            color: $dblue;
        }
        address{
            display: inline-block;
            margin: 1em 0;
            font-style: normal;
        }
        a{
            display: block;
            text-decoration: none;
        }
        @include media($bp-s){
            width: 100%;
        }
    }
    &__map{
        position: absolute;
        right: 0;
        height: 100%;
        width: 50%;
        @include media($bp-s){
            position: relative;
            width: 100%;
            height: 50%;
        }
        [id^=map]{
            height: 100% !important;
        }
    }
}